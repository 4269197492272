import React, { useEffect, useRef, useState } from 'react';
import './ImageSection.css';

const ImageSection = () => {
    const [animate, setAnimate] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setAnimate(true); 
                } else {
                    setAnimate(false);
                }
            },
            { threshold: 0.5 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    const handleLearnMoreClick = () => {
        const section = document.getElementById("aylix-dashboard");
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleGetStartedClick = () => {
        const section = document.getElementById("experience-section");
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <section className="main-image-section" ref={sectionRef}>
    <div className="overlay-text">
        <h1>
            <span className="highlight">Your Enterprise Intelligence 
            <br /> Amplified by Aylix </span>
        </h1>
        <p>
            <span className="highlight1">Unlock answers from your data with</span> 
            <span className="highlight2"> role-based dashboards and intelligent search</span>
        </p>
    </div>

    <img className="mobile-home-gif" src="images/hero3.gif" alt="Mobile GIF" />

    <div className="text">
        <p>
            Aylix, a cutting-edge Generative AI platform, delivers secure, role-specific access to transform enterprise data into actionable insights. With tailored dashboards and automated analytics, it empowers teams to make data-driven decisions effortlessly. Natural language queries enable users to uncover answers instantly, simplifying complex data exploration.
        </p>
    </div>
    <div className={`main-nav-container ${animate ? 'animate' : ''}`}>
        <div className="gt-button-container">
            <button className="get-started-btn" onClick={handleGetStartedClick}>Get Started</button>
            <button className="learn-more-btn" onClick={handleLearnMoreClick}>Learn More</button>
        </div>   
    </div>
    <div className="video-container">
        <img className="video" src="images/hero3.gif" alt="Hero GIF" />
    </div>
</section>
    );
};

export default ImageSection;
