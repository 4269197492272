import axios from 'axios';

const awsInstance = axios.create({
  //baseURL: 'http://localhost:5002',
  baseURL: 'https://api.aylix.world',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Origin': "https://aylix.world",
    'x-api-key': 'PvWqPq3Nqg6mQQdqZp4bp8rad425bldV59TynFqI',
  },
});

const geminiInstance = axios.create({
  //baseURL: 'http://localhost:8001',
  baseURL: 'https://gcp-aylix-final-893933615863.us-central1.run.app',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Origin': "https://aylix.world",
  },
});

const openaiInstance = axios.create({
  //baseURL: 'http://localhost:5004',
  baseURL: 'https://azure.aylix.world',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Origin': "https://aylix.world",
  },
});

awsInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

geminiInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

openaiInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export { awsInstance, geminiInstance, openaiInstance };
