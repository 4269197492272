import React from 'react';
import ImageSection from './ImageSection';
import AylixDashboard from './AylixDashboard';
import AylixFeatures from './AylixFeatures';
import ExperienceAylix from './ExperienceAylix';

const MainPage = () => {
    return (
        <div className="main-page">
            <ImageSection />
            <AylixDashboard />
            <AylixFeatures />
            <ExperienceAylix />
        </div>
    );
};

export default MainPage;
