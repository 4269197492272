import React from 'react';
import './AylixDashboard.css';

const AylixDashboard = () => {
  return (
    <div id="aylix-dashboard" className="dashboard-section">
      <div className="header-text">
        <h1>
          <span className="highlight">Transforming Data into</span> 
          <br /> 
          <span className="highlight">Actionable Insights</span>
        </h1>
        <p>
        Aylix revolutionizes how enterprises harness their data, turning it into a strategic asset. Our generative AI platform delivers prescriptive and descriptive insights that empower organizations to optimize operations, elevate efficiency, and achieve ambitious goals.
        </p>
      </div>

      <div className="dashboard-content">
        <img 
          src="images/lap.png" 
          alt="Dashboard Preview" 
          className="dashboard-image"
        />
        <div className="db-features">
          <div className="feature-box">
            <h3>Real-Time Insights</h3>
            <p>
            Aylix provides dynamic data analysis and visualization tailored to your enterprise's specific needs, enabling real-time, informed decision-making.
            </p>
          </div>
          <div className="feature-box">
            <h3>Intelligent Reporting</h3>
            <p>
            Aylix automates the generation of insightful, descriptive analytics, offering actionable intelligence on key performance metrics while minimizing manual effort.
            </p>
          </div>
          <div className="feature-box">
            <h3>Data Security</h3>
            <p>
            With robust role-based access control (RBAC) and advanced encryption, Aylix safeguards sensitive data and ensures adherence to industry standards and regulations.
            </p>
          </div>
          <div className="feature-box">
            <h3>Predictive Analytics</h3>
            <p>
            Using cutting-edge algorithms, Aylix forecasts trends and outcomes, empowering organizations to anticipate risks and capitalize on emerging opportunities.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AylixDashboard;
