import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './components/MainPage';
import Login from './components/chatbot/Login';
import Chatbot from './components/chatbot/Chatbot';
import Register from './components/chatbot/Register';
import PrivacyPolicy from './components/PrivacyPolicy';
import Header from './components/Header';
//import Home from './Home';
import About from './components/About';

const App = () => {
  return (
    <Router>
      <Header/>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/chatbot" element={<Chatbot />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </Router>
  );
};

export default App;
