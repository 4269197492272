import React from 'react';
import './AylixFeatures.css';

const AylixFeatures = () => {
  return (
    <div className="features-section">
      <h1>
        <span className="highlight1">A New Era of Data Analysis</span>
      </h1>
      <div className="feature-cards">
        <div className="feature-card">
          <img src="images/custom_dashboard.jpg" alt="Customizable Dashboards" />
          <h3>Customizable Dashboards</h3>
          <p>
          Experience dashboards tailored to your role, providing intuitive access to relevant data and an enhanced user experience.
          </p>
        </div>
        <div className="feature-card">
          <img src="images/Automated_insights.png" alt="Automated Insights" />
          <h3>Automated Insights</h3>
          <p>
          Aylix delivers actionable insights automatically, streamlining decision-making processes and saving valuable time.
          </p>
        </div>
        <div className="feature-card">
          <img src="images/Access_control-1.png" alt="Role-Based Access Control" />
          <h3>Secure Role-Based Access</h3>
          <p>
          Ensure data privacy and security with robust role-based access controls, granting permissions only to authorized users.
          </p>
        </div>
        <div className="feature-card">
          <img src="images/Built-in-tool.jpg" alt="Built-In Search Tool" />
          <h3>Intelligent Search Functionality</h3>
          <p>
          Effortlessly locate key data and insights using Aylix's advanced search, designed for precision and speed.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AylixFeatures;
